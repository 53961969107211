<template>
  <div class="mobileMenuToggle">
    <img v-on:click="toggleMenu" class="icn_hamburger" src="@/assets/images/svg/hamburgerSVG.svg" alt="Mobile Menu Toggle">
  </div>
</template>

<script>
export default {
  name: 'mobileMenuToggle',
  data: function () {
      return {
          expanded: false
      }
  },
  methods: {
      toggleMenu: function (event) {
          const menu = document.getElementById('nav');
          if ( this.expanded == false ) {
              this.expanded = true
              menu.classList.add("expanded");
              return
          }

          if ( this.expanded == true ) {
              this.expanded = false
              menu.classList.remove("expanded");
              return
          }
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .mobileMenuToggle {
        .icn_hamburger {
            position: absolute;
            display: inline-block;
            width:22px;
            height: 22px;
            left: 15px;
            top: 22px;
        }
    }
</style>
