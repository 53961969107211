<template>
  <div class="text-center">
    <v-dialog v-model="showModal" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 primary--text">
          {{ $t("message.modals.voucher") }}
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="validUseVoucher" lazy-validation>
            <v-layout row wrap>
              
              <v-flex cols="12" sm="6" md="6">
                <v-text-field v-model="code" :label=compLabel></v-text-field>
              </v-flex>
             
            </v-layout>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions >
          <v-spacer></v-spacer>
          <v-btn outline color="primary" text @click="showModal = false">
            {{ $t("message.close") }}
          </v-btn>
          <v-btn color="primary" @click="useVoucher()">
            {{ $t("message.modals.useVoucher") }}
          </v-btn> 
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :top="true" :right="true" :timeout="timeout">
        {{ text }}
        <v-btn dark flat @click="snackbar = false">{{
            $t("message.close")
            }}</v-btn>
    </v-snackbar>

  </div>
</template>
<script>
const axios = require("axios");
axios.defaults.withCredentials = true;
export default {
  components: {
  },
  data() {
    return {
      code: null,
      validUseVoucher: true,
      rules: {
        required: value => !!value || "Required.",
        min: v => v.length >= 4 || "Min 4 characters"
      },
      snackbar: false,
      timeout: 6000,
      text: "",
      // edit: {
      //   unitStatus: null,
      //   confirmation: null
      // },
      // statusOptions: ["Available", "Unavailable", "Booked"],
      // showConfirmDelete: false,
      // unitLocations: ["RAMA 9", "LKB", "LCB"]
    };
  },
  props: ["showUseVoucherModal", ],
  methods: {
    useVoucher() {
      // send to api for processing
      let vm = this;
      axios.defaults.withCredentials = true;
      axios.defaults.headers.common = {
          Authorization: `Bearer ${vm.$store.state.token}`,
      };
      axios
          .post(vm.$root.urls.api + "/v3/voucher/apply", {
              code: vm.code
          })
          .then(function(response) {
            console.log(response.data)
            if(response.data.valid === true) {
              vm.text = "Voucher successfully applied.";
              vm.snackbar = true;
              // if valid, update credit in state
              vm.credit = response.data.credit
              // clear form voucher code
              vm.code = null;

            } else {
              vm.text = response.data.errorMsg;
              vm.snackbar = true;
            }
            

          })
          .catch(function(error) {
              console.log(error);
              vm.text = "Unable to apply your voucher.";
              vm.snackbar = true;
          });

      
    },
  },
  computed: {
    token() {
      return this.$store.state.user.token;
    },
    showModal: {
      get: function() {
        return this.showUseVoucherModal;
      },
      set: function(value) {
        // this.edit = {
        //   unitStatus: null,
        //   confirmation: null
        // };
        // reset modal
        this.$emit("closeUseVoucherModal");
      }
    },
    credit: {
      get: function() {
        return this.$store.state.user.credit;
      },
      set: function(newValue) {
        this.$store.commit("updateUser", {
            credit: newValue,
        });
      }
    },
    compLabel() {
      return this.$t("message.modals.enterVoucher")
    }
  },
  watch: {
    // pricing(val) {
    //   if (val) {
    //     this.edit = val;
    //   }
    // }
  }
  // mounted() {
  //     if(this.unit) {
  //         this.edit = this.unit
  //     }
  // }
};
</script>
