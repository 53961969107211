<template>
  <div class="profileLink">
    <v-menu offset-y id="btn_profile_desktop" class="">
      <v-btn text flat slot="activator">
        <span>{{ profileName }} </span>
        <img
          class="icn_arrow"
          src="@/assets/images/svg/arrow_down_inactive.svg"
          alt="User Profile Toggle"
        />
      </v-btn>
      <v-list>
        <v-list-tile
          v-for="(item, index) in items"
          :key="index"
          :to="item.link"
        >
          <v-list-tile-title>{{ item.title }}</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "ProfileLink",
  data: function () {
    return {
      //  items: [
      //       { title: 'My Bookings', link: '/customers/bookings'  },
      //       { title: 'My Access Log', link: '/customers/access-log'  },
      //       { title: 'My Points', link: '/customers/points'  },
      //       { title: 'My Account', link: '/customers/account'  },
      //       { title: 'Sign Out', link: '/logout' }
      //   ]
    };
  },
  computed: {
    profileName: function () {
      return this.$store.state.profileName;
    },
    items: function () {
      return [
        {
          title: this.$t("message.mainNav.myBookings"),
          link: "/customers/bookings/",
        },
        {
          title: this.$t("message.mainNav.myAccess"),
          link: "/customers/access-log/",
        },
        // {
        //     title: this.$t("message.mainNav.myPoints"),
        //     link: '/customers/points/'
        // },
        {
          title: this.$t("message.mainNav.myAccount"),
          link: "/customers/account/",
        },
        {
          title: this.$t("message.mainNav.membership"),
          link: "/customers/membership/",
        },
        {
          title: this.$t("message.mainNav.signOut"),
          link: "/logout",
        },
      ];
    },
  },
  mounted: function () {
    //    let vm = this
  },
};
</script>

<style scoped lang="scss">
.profileLink {
  .icn_arrow {
    margin-left: 5px;
    display: block;
  }
}

// mobile styles
@media (max-width: 812px) {
  .profileLink {
  }
}
</style>
