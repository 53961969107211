<template>
  <div class="setPassCode">
    <v-dialog v-model="setPassCodeDialog" width="700">
      <v-card class="signin-card">
        <v-btn id="btn_cancel" color="primary" flat @click="setPassCodeDialog = false">X</v-btn>

        <v-card-title class="text-xs-center" primary-title>
          <h3>{{ $t("message.passcode.title") }}</h3>
        </v-card-title>

        <v-card-text>
          <div class="setpasscode-body">
            <v-form ref="form" v-model="valid" lazy-validation>
              <p>{{ $t("message.passcode.your") }}</p>
              <p class="storage-number">{{ bookingUnitNumber }}</p>
              <p>{{ $t("message.passcode.intro") }}</p>
              <v-text-field v-model="pc1" mask="#" outline required :rules="requiredRules" disabled></v-text-field>
              <v-text-field v-model="pc2" mask="#" outline required :rules="requiredRules" disabled></v-text-field>
              <v-text-field v-model="pc3" mask="#" outline required :rules="requiredRules" disabled></v-text-field>
              <v-text-field v-model="pc4" mask="#" outline required :rules="requiredRules" disabled></v-text-field>
              <v-text-field
                v-model="pc5"
                id="pc5"
                mask="#"
                outline
                required
                :rules="requiredRules"
                @keyup="horse(6)"
                onfocus="this.select();"
                onmouseup="return false;"
              ></v-text-field>
              <v-text-field
                v-model="pc6"
                id="pc6"
                mask="#"
                outline
                required
                :rules="requiredRules"
                @keyup="horse(7)"
                onfocus="this.select();"
                onmouseup="return false;"
              ></v-text-field>
              <v-text-field
                v-model="pc7"
                id="pc7"
                mask="#"
                outline
                required
                :rules="requiredRules"
                @keyup="horse(8)"
                onfocus="this.select();"
                onmouseup="return false;"
              ></v-text-field>
              <v-text-field
                v-model="pc8"
                id="pc8"
                mask="#"
                outline
                required
                :rules="requiredRules"
                @keyup="horse(9)"
                onfocus="this.select();"
                onmouseup="return false;"
              ></v-text-field>
              <v-text-field
                v-model="pc9"
                id="pc9"
                mask="#"
                outline
                required
                :rules="requiredRules"
                @keyup="horse(21)"
                onfocus="this.select();"
                onmouseup="return false;"
              ></v-text-field>
              <!-- <v-text-field
                    v-model="pc10"
                    id="pc10"
                    mask="#"
                    outline
                    required
                    :rules="requiredRules"
                    @keyup="horse(21)"
                    onfocus="this.select();" onmouseup="return false;"
              ></v-text-field>-->

              <p>&nbsp;</p>

              <p class="ma-3">{{ $t("message.passcode.retype") }}</p>

              <v-text-field
                v-model="pc21"
                id="pc21"
                mask="#"
                outline
                required
                :rules="requiredRules"
                @keyup="horse(22)"
                onfocus="this.select();"
                onmouseup="return false;"
              ></v-text-field>
              <v-text-field
                v-model="pc22"
                id="pc22"
                mask="#"
                outline
                required
                :rules="requiredRules"
                @keyup="horse(23)"
                onfocus="this.select();"
                onmouseup="return false;"
              ></v-text-field>
              <v-text-field
                v-model="pc23"
                id="pc23"
                mask="#"
                outline
                required
                :rules="requiredRules"
                @keyup="horse(24)"
                onfocus="this.select();"
                onmouseup="return false;"
              ></v-text-field>
              <v-text-field
                v-model="pc24"
                id="pc24"
                mask="#"
                outline
                required
                :rules="requiredRules"
                @keyup="horse(25)"
                onfocus="this.select();"
                onmouseup="return false;"
              ></v-text-field>
              <v-text-field
                v-model="pc25"
                id="pc25"
                mask="#"
                outline
                required
                :rules="requiredRules"
                @keyup="horse(26)"
                onfocus="this.select();"
                onmouseup="return false;"
              ></v-text-field>
              <v-text-field
                v-model="pc26"
                id="pc26"
                mask="#"
                outline
                required
                :rules="requiredRules"
                @keyup="horse(27)"
                onfocus="this.select();"
                onmouseup="return false;"
              ></v-text-field>
              <v-text-field
                v-model="pc27"
                id="pc27"
                mask="#"
                outline
                required
                :rules="requiredRules"
                @keyup="horse(28)"
                onfocus="this.select();"
                onmouseup="return false;"
              ></v-text-field>
              <v-text-field
                v-model="pc28"
                id="pc28"
                mask="#"
                outline
                required
                :rules="requiredRules"
                @keyup="horse(29)"
                onfocus="this.select();"
                onmouseup="return false;"
              ></v-text-field>
              <v-text-field
                v-model="pc29"
                id="pc29"
                mask="#"
                outline
                required
                :rules="requiredRules"
                onfocus="this.select();"
                onmouseup="return false;"
              ></v-text-field>
              <!-- <v-text-field
                    v-model="pc210"
                    id="pc210"
                    mask="#"
                    outline
                    required
                    :rules="requiredRules"
                    onfocus="this.select();" onmouseup="return false;"
              ></v-text-field>-->

              <p v-if="passCodeWarning" class="pass-code-error">{{ $t("message.passcode.warning") }}</p>

              <v-layout row wrap mt-5>
                <v-flex>
                  <v-btn outline color="primary" @click="resetPasscode()">Reset</v-btn>

                  <v-btn
                    outline
                    color="primary"
                    @click="setPassCodeDialog = false"
                  >{{ $t("message.passcode.skip") }}</v-btn>

                  <v-btn
                    depressed
                    color="primary"
                    :disabled="!valid"
                    @click="validate"
                  >{{ $t("message.passcode.done") }}</v-btn>
                </v-flex>
              </v-layout>
            </v-form>
          </div>
        </v-card-text>

        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      :top="true"
      :right="true"
      :color="color"
      :multi-line="mode === 'multi-line'"
      :timeout="timeout"
      :vertical="mode === 'vertical'"
    >
      {{ text }}
      <v-btn dark flat @click="snackbar = false">{{ $t("message.close") }}</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
const axios = require("axios");
axios.defaults.withCredentials = true;
export default {
  name: "SignInLink",
  valid: false,
  data: function() {
    return {
      pc1: "",
      pc2: "",
      pc3: "",
      pc4: "",
      pc5: "",
      pc6: "",
      pc7: "",
      pc8: "",
      pc9: "",
      pc10: "",
      pc21: "",
      pc22: "",
      pc23: "",
      pc24: "",
      pc25: "",
      pc26: "",
      pc27: "",
      pc28: "",
      pc29: "",
      pc210: "",
      valid: true,
      debug: "test",
      dialog: false,
      requiredRules: [v => !!v || "*"],
      passCodeWarning: false,
      snackbar: false,
      color: "",
      mode: "",
      timeout: 60000,
      text: "Hello, I'm a snackbar"
    };
  },
  computed: {
    setPassCodeDialog: {
      get: function() {
        return this.$store.state.setPassCodeDialog;
      },
      set: function(newValue) {
        this.$store.commit("toggleSetPassCodeDialog", { newValue });
      }
    },
    bookingID: {
      get: function() {
        return this.$store.state.bookingConfirmation;
      }
    },
    bookingUnitNumber: {
      get: function() {
        return this.$store.state.bookingUnitNumber;
      }
    },
    passCode: function() {
      return (
        this.pc1 +
        this.pc2 +
        this.pc3 +
        this.pc4 +
        this.pc5 +
        this.pc6 +
        this.pc7 +
        this.pc8 +
        this.pc9
      );
    },
    passCodeConfirm: function() {
      return (
        this.pc21 +
        this.pc22 +
        this.pc23 +
        this.pc24 +
        this.pc25 +
        this.pc26 +
        this.pc27 +
        this.pc28 +
        this.pc29
      );
    }
  },
  methods: {
    updatebookings(event) {
      console.log(`set passcode emitted: ${event}`);
    },
    resetPasscode() {
      let vm = this;
      vm.pc5 = "";
      vm.pc6 = "";
      vm.pc7 = "";
      vm.pc8 = "";
      vm.pc9 = "";
      // vm.pc10 = ''
      vm.pc21 = "";
      vm.pc22 = "";
      vm.pc23 = "";
      vm.pc24 = "";
      vm.pc25 = "";
      vm.pc26 = "";
      vm.pc27 = "";
      vm.pc28 = "";
      vm.pc29 = "";
    },
    validate() {
      if (this.$refs.form.validate()) {
        if (this.passCode != this.passCodeConfirm) {
          //    this.valid = false;
          this.passCodeWarning = true;
          return;
        } else {
          this.passCodeWarning = false;
          // Close modal:
          //    this.setPassCodeDialog = false
          // TODO send passcode to database:
          this.updatePasscode();
        }
      }
    },
    updatePasscode: function() {
      let vm = this;
      // console.log('passcode: ' + vm.passCode)
      // console.log('booking id: ' + vm.bookingID)
      axios
        .put(vm.$root.urls.api + "/bookings/passcode", {
          passcode: vm.passCode,
          bookingID: vm.bookingID
        })
        .then(function(response) {
          // clear form passcode:
          vm.pc5 = "";
          vm.pc6 = "";
          vm.pc7 = "";
          vm.pc8 = "";
          vm.pc9 = "";
          // vm.pc10 = ''
          vm.pc21 = "";
          vm.pc22 = "";
          vm.pc23 = "";
          vm.pc24 = "";
          vm.pc25 = "";
          vm.pc26 = "";
          vm.pc27 = "";
          vm.pc28 = "";
          vm.pc29 = "";
          // vm.pc210 = ''

          // show message in snackbar:
          vm.text = vm.$t("message.passcode.saved");
          vm.snackbar = true;

          // close dialog:
          vm.setPassCodeDialog = false;
          // update booking details on page:
          vm.$socket.emit("apiDataUpdate", "BOOKINGS");
        })
        .catch(function(error) {
          // vm.text = "Unable to update contact information"
          // vm.snackbar = true
        });
    },
    horse(input) {
      // var currentInput = input
      var currentInputElement = "pc" + input;
      var t = document.getElementById(currentInputElement);
      t.focus();
    }
  },
  watch: {
    bookingUnitNumber: function(newNumber, oldNumber) {
      let vm = this;
      let bookingUnitNumber4 = ("0000" + newNumber).substr(-4, 4);
      vm.pc1 = bookingUnitNumber4.substring(0, 1);
      vm.pc2 = bookingUnitNumber4.substring(1, 2);
      vm.pc3 = bookingUnitNumber4.substring(2, 3);
      vm.pc4 = bookingUnitNumber4.substring(3, 4);
    }
    // pc5: function () {
    //     // if ( newNumber != oldNumber ) {
    //         console.log('its now typed!')
    //     // }
    // }
  }
};
</script>

<style lang="scss">
.setpasscode-body {
  font-weight: 700;
  .storage-number {
    color: #dd0031;
    font-size: 22px;
  }
  .v-text-field {
    width: 40px !important;
    height: 40px !important;
    display: inline-block;
    margin: 2px !important;
  }
  input[type="text"] {
    margin-top: 12px !important;
    font-size: 20px !important;
  }
  .v-text-field--outline > .v-input__control > .v-input__slot {
    background: transparent !important;
    border-radius: 0px !important;
  }
  .theme--light.v-text-field--outline > .v-input__control > .v-input__slot {
    border: 1px solid rgba(0, 0, 0, 0.54) !important;
  }
  p.pass-code-error {
    color: #dd0031;
    margin-top: 30px;
  }
}

@media (max-width: 812px) {
  .setpasscode-body .v-text-field {
    margin-top: 20px !important;
  }
}
</style>
