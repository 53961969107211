<template>
  <div id="app">
    <v-app>
      <v-navigation-drawer fixed v-model="drawer" app class="hidden-md-and-up">
        <v-list class="main-nav main-nav-mobile">
          <v-list-tile v-for="(item, index) in publicMenuItems" :key="index">
            <v-list-tile-title>
              <a v-bind:href="item.link" v-bind:class="item.class">{{
                item.title
              }}</a>
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
        <ul class="main-nav-mobile-language">
          <li v-bind:class="{ active: $i18n.locale == 'en' }">
            <button
              class="nav"
              v-on:click="
                changeLocale('en');
                drawer = false;
              "
            >
              ENG
            </button>
          </li>
          <li v-bind:class="{ active: $i18n.locale == 'th' }">
            <button
              class="nav"
              v-on:click="
                changeLocale('th');
                drawer = false;
              "
            >
              ไทย
            </button>
          </li>
        </ul>
      </v-navigation-drawer>

      <v-toolbar id="top_toolbar">
        <a
          v-if="logo === 'ARYU'"
          href="https://aryu.global/"
          class="hidden-md-and-up"
        >
          <img
            id="redd_logo_mobile"
            src="./assets/images/aryu.png"
            alt="ARYU"
            width="70"
          />
        </a>
        <a v-else href="https://redd.co.th" class="hidden-md-and-up">
          <img
            id="redd_logo_mobile"
            src="./assets/images/svg/REDD-Premium-Self-Storage.svg"
            alt="REDD"
            width="110px"
          />
        </a>
        <v-spacer></v-spacer>
        <ul id="menu-public-top" class="zhidden-sm-and-down">
          <li v-bind:class="{ active: $i18n.locale == 'en' }">
            <button class="nav" v-on:click="changeLocale('en')">ENG</button>
          </li>
          <li v-bind:class="{ active: $i18n.locale == 'th' }">
            <button class="nav" v-on:click="changeLocale('th')">ไทย</button>
          </li>
        </ul>

        <SignInLink v-if="!loggedIn" />
        <ProfileLink v-if="loggedIn" />
        <!-- <Notification v-if="loggedIn" /> -->
      </v-toolbar>

      <v-toolbar id="bottom_toolbar" class="hidden-sm-and-down">
        <a v-if="logo === 'ARYU'" href="https://aryu.global/">
          <img
            id="redd_logo"
            src="./assets/images/aryu.png"
            alt="ARYU"
            width="170"
          />
        </a>
        <a v-else href="https://redd.co.th">
          <!-- <img id="redd_logo" src="./assets/images/redd-logo.svg" alt="REDD" /> -->
          <img
            id="redd_logo"
            src="./assets/images/svg/REDD-Premium-Self-Storage.svg"
            alt="REDD"
            width="170px"
          />
          <!-- <img id="redd_logo" src="./assets/images/aryu.png" alt="ARYU" width="170" /> -->
        </a>
        <v-spacer></v-spacer>
        <ul class="main-nav">
          <li>
            <a href="https://redd.co.th">REDD</a>
          </li>
          <li>
            <a href="https://aryu.global/">ARYU</a>
          </li>
          <li>
            <v-btn outline color="primary" large to="/book" id="btn_book_now">{{
              $t("message.mainNav.bookNow")
            }}</v-btn>
          </li>
        </ul>
      </v-toolbar>
      <v-content>
        <router-view
          @showUseVoucherModal="showUseVoucherModal = true"
          @showCreditHistoryModal="showCreditHistoryModal = true"
        />
      </v-content>
      <!-- <Footer /> -->
      <SetPassCode />
    </v-app>

    <modalUseVoucher
      :showUseVoucherModal="showUseVoucherModal"
      @closeUseVoucherModal="showUseVoucherModal = false"
    />

    <modalCreditHistory
      :showCreditHistoryModal="showCreditHistoryModal"
      @closeCreditHistoryModal="showCreditHistoryModal = false"
    />
  </div>
</template>

<script>
import globalStyles from "@/assets/scss/global.scss";
import pageStyles from "@/assets/scss/pages.scss";
// import Notification from "@/components/Notification.vue";
import SignInLink from "@/components/SignInLink.vue";
import SetPassCode from "@/components/SetPassCode.vue";
import ProfileLink from "@/components/ProfileLink.vue";
import MobileMenuToggle from "@/components/MobileMenuToggle.vue";
import Footer from "@/components/Footer.vue";
import modalUseVoucher from "@/components/modalUseVoucher.vue";
import modalCreditHistory from "@/components/modalCreditHistory.vue";
const axios = require("axios");
export default {
  mounted: function () {
    let vm = this;
  },
  components: {
    // Notification,
    ProfileLink,
    SignInLink,
    SetPassCode,
    MobileMenuToggle,
    Footer,
    modalUseVoucher,
    modalCreditHistory,
  },
  data: () => ({
    drawer: false,
    activeLang: "en",
    publicMenuItems: [
      { title: "REDD", link: "https://redd.global/", class: "nav" },
      { title: "ARYU", link: "https://aryu.global/", class: "nav" },
      {
        title: "Services",
        link: "https://redd.global/service/",
        class: "nav",
      },
      {
        title: "Sizes & Prices",
        link: "https://redd.global/size-guide-price-table/",
        class: "nav",
      },
      {
        title: "Plan Your Visit",
        link: "https://redd.global/plan-your-visit/",
        class: "nav",
      },
      {
        title: "Terms & Conditions",
        link: "https://redd.global/terms-conditions/",
        class: "nav",
      },
      {
        title: "REDD KLUBB",
        link: "https://redd.global/rewards/",
        class: "nav",
      },
    ],
    showUseVoucherModal: false,
    showCreditHistoryModal: false,
  }),
  computed: {
    loggedIn() {
      return this.$store.state.loggedIn;
    },
    logo() {
      switch (this.$store.state.bookingLocation) {
        case "RAMA9":
        case "RAMA 9":
        case "พระราม 9":
          return "RAMA9";
        case "ARYU Lat Krabang":
        case "ARYU Laem Chabang":
        case "แหลมฉบัง":
        case "ลาดกระบัง":
        case "ARYU":
          return "ARYU";
      }
    },
  },
  methods: {
    changeLocale: function (locale) {
      this.$i18n.locale = locale;
      // change language param on html
      var h = document.querySelector("html");
      h.setAttribute("lang", locale);
    },
  },
  watch: {
    $route: function () {
      window.scrollTo(0, 0);
    },
  },
  created: function () {
    let vm = this;

    let token = localStorage.getItem("reddtoken");
    if (token) {
      console.log("found token");
      // parse token for user info
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      let user = JSON.parse(jsonPayload);

      if (user.username) {
        // query API for user profile data
        axios.defaults.headers.common = {
          Authorization: `Bearer ${token}`,
        };
        axios
          .get(vm.$root.urls.api + "/customers/" + user.user_id)
          .then(function (response) {
            console.log("getting profile data");
            // console.log(response.data[0])
            let userData = response.data[0];
            if (typeof userData.companyProvince === "undefined")
              userData.companyProvince = "";
            if (typeof userData.companyDistrict === "undefined")
              userData.companyDistrict = "";
            if (typeof userData.companySubDistrict === "undefined")
              userData.companySubDistrict = "";
            if (typeof userData.companyPostcode === "undefined")
              userData.companyPostcode = "";
            if (typeof userData.companyCountry === "undefined")
              userData.companyCountry = "Thailand";

            vm.$store.commit("login", {
              user: userData,
              token,
            });
          });
      } else {
        // vm.$router.push("/book");
      }
    } else {
      let currentPage = vm.$route.name;
      if (
        currentPage !== "book" &&
        currentPage !== "password-reset" &&
        currentPage !== "dev"
      ) {
        // vm.$router.push("/book");
      }
      // console.log(vm.$route);
    }
  },
};
</script>
