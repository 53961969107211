<template>
  <div class="text-center">
    <v-dialog v-model="showModal" width="800" persistent>
      <v-card>
        <v-card-title class="text-h5 primary--text">
          {{ $t("message.modals.creditHistory") }}
        </v-card-title>
        <v-card-text>
          <p>{{ $t("message.modals.creditHistoryIntro") }}</p>
        </v-card-text>
        <v-data-table
            :headers="compHeaders"
            :items="creditHistory"
            class="elevation-1"
          >
            <template v-slot:items="props">
              <td><FancyDate :date="props.item.createdOn" /></td>
              <td class="text-xs-right">{{ props.item.amount }}</td>
              <td class="text-xs-right">{{ props.item.previousBalance }}</td>
              <td class="text-xs-right">{{ props.item.newBalance }}</td>
              <td class="text-xs-right">{{ props.item.bookingID }}</td>
              <td class="text-xs-right">

                <v-tooltip left>
                     <template v-slot:activator="{ on }">
                       <span v-on="on" class="primary--text">{{ $t("message.modals.details") }}</span>
                     </template>
                     <span>{{ translate(props.item.notes) }}</span>
                   </v-tooltip>
                
              </td>
            </template>
          </v-data-table>
        <v-divider></v-divider>
        <v-card-actions >
          <v-spacer></v-spacer>
          <v-btn outline color="primary" text @click="showModal = false">
            {{ $t("message.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
  const moment = require("moment");
const axios = require("axios");
axios.defaults.withCredentials = true;
import FancyDate from "@/components/fancyDate.vue";
export default {
  components: {
    FancyDate
  },
  data() {
    return {
      code: null,
      validUseVoucher: true,
      rules: {
        required: value => !!value || "Required.",
        min: v => v.length >= 4 || "Min 4 characters"
      },
      snackbar: false,
      timeout: 6000,
      text: "",
      creditHistory: [],
      // headers: [{
      //     text: "Date",
      //     align: "left",
      //     value: "createdOn"
      //   },
      //   {
      //     text: "Amount",
      //     align: "right",
      //     value: "amount"
      //   },
      //   {
      //     text: "Previous Balance",
      //     align: "right",
      //     value: "previousBalance"
      //   },
      //   {
      //     text: "New Balance",
      //     align: "right",
      //     value: "newBalance"
      //   },
      //   {
      //     text: "Booking No.",
      //     align: "right",
      //     value: "bookingID"
      //   },
      //   {
      //     text: "Notes",
      //     align: "right",
      //     value: "notes"
      //   }]
    };
  },
  props: ["showCreditHistoryModal", ],
  methods: {
    getCreditHistory() {
      let vm = this;
      axios.defaults.withCredentials = true;
      axios.defaults.headers.common = {
          Authorization: `Bearer ${vm.$store.state.token}`,
      };
      axios.get(vm.$root.urls.api + `/v3/credit/history`,)
          .then(function(response) {
              vm.creditHistory = response.data
          })
    },
    prettyDate: function (d) {
      return moment(d).format("DD MMM YYYY")
    },
    translate(txt) {
      if (this.$i18n.locale === 'en') {
        return txt
      }
      switch(txt) {
      case 'Administrative credit adjustment':
        return 'เจ้าหน้าที่ปรับเปลี่ยนเครดิต';
      case 'Booking discount':
        return 'ส่วนลดค่าบริการ'
      }

      let txtArray = txt.split(" ");
      if(txtArray[0]==='Applied') {
        return `ใช้งานบัตรกำนัล ${txtArray[2]}`
      }

    }
  },
  computed: {
    compHeaders() {
      return [{
          text: this.$t("message.modals.date"),
          align: "left",
          value: "createdOn"
        },
        {
          text: this.$t("message.modals.amount"),
          align: "right",
          value: "amount"
        },
        {
          text: this.$t("message.modals.previousBalance"),
          align: "right",
          value: "previousBalance"
        },
        {
          text: this.$t("message.modals.newBalance"),
          align: "right",
          value: "newBalance"
        },
        {
          text: this.$t("message.modals.bookingNo"),
          align: "right",
          value: "bookingID"
        },
        {
          text: this.$t("message.modals.notes"),
          align: "right",
          value: "notes"
        }]
    }, 
    compLabelDate() {
      return this.$t("message.modals.date")
    },
    token() {
          return this.$store.state.user.token;
        },
    showModal: {
      get: function() {
        return this.showCreditHistoryModal;
      },
      set: function(value) {
        // reset modal
        this.$emit("closeCreditHistoryModal");
      }
    },
  },
  watch: {
    showModal(val) {
      if (val) this.getCreditHistory()
    }
  },
  mounted() {
      // this.getCreditHistory()
  }
};
</script>
