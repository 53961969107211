import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
      path: '/password-reset/:secret/:username',
      name: 'password-reset',
      component: () => import( /* webpackChunkName: "password-reset" */ './views/Password-reset.vue')
    },
    {
      path: '/dev',
      name: 'Dev',
      component: () => import( /* webpackChunkName: "dev" */ './views/Dev.vue')
    },
    {
      path: '/customers/bookings/',
      name: 'customers-bookings',
      component: () => import( /* webpackChunkName: "customers-bookings" */ './views/customers/Bookings.vue')
    },
    {
      path: '/customers/access-log/',
      name: 'customers-access-log',
      component: () => import( /* webpackChunkName: "customers-access-log" */ './views/customers/Access-log.vue')
    },
    {
      path: '/customers/points/',
      name: 'customers-points',
      component: () => import( /* webpackChunkName: "customers-points" */ './views/customers/Points.vue')
    },
    {
      path: '/customers/account/',
      name: 'customers-account',
      component: () => import( /* webpackChunkName: "customers-account" */ './views/customers/Account.vue')
    },
    {
      path: '/customers/membership/',
      name: 'customers-membership',
      component: () => import( /* webpackChunkName: "customers-membership" */ './views/customers/Membership.vue')
    },
    {
      path: '/book/:location?',
      name: 'book',
      component: () => import( /* webpackChunkName: "book" */ './views/Book.vue')
    },
    {
      path: '/book-pay',
      name: 'book-pay',
      component: () => import( /* webpackChunkName: "book-pay" */ './views/Book-pay.vue')
    },
    {
      path: '/book-confirmation/:bookingid/:unique_id?',
      name: 'book-confirmation',
      component: () => import( /* webpackChunkName: "book-confirmation" */ './views/Book-confirmation.vue')
    },
    {
      path: '/book-extend/:bookingid/:action',
      name: 'book-extend',
      component: () => import( /* webpackChunkName: "book-extend" */ './views/Book-extend.vue')
    },
    {
      path: '/payment/confirmation/:source',
      name: 'payment-confirmation',
      component: () => import( /* webpackChunkName: "payment-confirmation" */ './views/payment/Confirmation.vue')
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import( /* webpackChunkName: "logout" */ './views/Logout.vue')
    },
    {
      path: '/:referral?',
      component: () => import( /* webpackChunkName: "book" */ './views/Book.vue')
    },
  ]
})
