<template>
  <div class="dateHTML">
    <span class="day">{{ day }}</span>
    <div class="details">
      <span>{{ month }}</span
      ><br /><span>{{ year }}</span>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "fancy-date",
  props: {
    date: String
  },
  data: () => ({
    day: null,
    month: null,
    year: null
  }),
  methods: {},
  mounted: function() {
    if (this.date) {
      this.day = moment(this.date).format("D");
      this.month = moment(this.date).format("MMM");
      this.year = moment(this.date).format("YYYY");
    }
  },
  watch: {
    date(val) {
      if (this.date) {
        this.day = moment(this.date).format("D");
        this.month = moment(this.date).format("MMM");
        this.year = moment(this.date).format("YYYY");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dateHTML {
  display: flex;
}

.day {
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  text-align: right;
  padding-right: 5px;
}

.details {
  line-height: 15px;
  margin-top: 5px;
}

/*
	.dateHTML {
  width: 93px;
  padding-left: 30px;

  span {
    font-weight: initial;
    font-size: 14px;
    float: right;
    text-align: left;
    width: 30px;
    line-height: 13px;
  }

  span:nth-child(2) {
    margin-top: 5px;
  }

  span:nth-child(3) {
    clear: right;
  }

  span:nth-child(1) {
  	width:60px;
    float: left;
    font-weight: bold;
    font-size: 24px;
    line-height: 40px;
    text-align: right;
  }
}*/
</style>
